import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import {HiOutlineArrowCircleRight} from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";
import styled from "styled-components";
import GrowthImg from "../../images/about_growth.jpg";
import {CONTAINER, H3, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const GrowthIsStrength = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content gutter={{xs: 8, sm: 32, md: 32, lg: 32}}>
          <Column sm={24} md={12} lg={8} xl={8}>
            <Box className="animate-in-shine animate-in-slide-up animate-out-slide-down" />
            <TextOverlay>
              <Title className="animate-in-slide-right animate-out-slide-left">Growth Is Strength</Title>
              <Paragraph className="animate-in-slide-left animate-out-slide-right">
                Our imperfection is what makes us strong. We know we aren't perfect, and that fuels the passion to grow stronger with each day.
              </Paragraph>
            </TextOverlay>
          </Column>
        </Content>
      </Container>
      <Icon className="animate-in-slide-right animate-out-slide-left" />
      <Line />
    </Wrapper>
  );
};
export default forwardRef(GrowthIsStrength);

const Wrapper = styled.section`
  background: transparent;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  position: relative;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 75vw;
    min-width: 75%;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Box = styled.div`
  width: 100%;
  height: 40vh;
  background: url(${GrowthImg});
  background-size: cover;
  box-shadow: 0px 0px 100px 0px rgba(255, 255, 255, .2);
  position: absolute;
  z-index: 0;
`;

const Content = styled(Row)`
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const Column = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`;

const TextOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const Title = styled.h2`
  ${H3}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  margin-bottom: 20px;
  z-index: 1;
  margin-top: 10%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
    margin-left: -35%;
    max-width: 50%;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  font-family: var(--unnamed-font-family-brandon-grotesque);
  text-align: right;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 60%;
    margin-top: 40px;
    margin-right: -45%;
    margin-bottom: 50px;
  }
`;

const Line = styled.div`
  height: 2px;
  width: 67%;
  background-color: #FF3D00;
  position: absolute;
  display: block;
  left: 33%;
  top: 80%;
  
   @media (min-width: ${BREAKPOINTS['md']}px) {
    top: 65%;
  }
`;

const Icon = styled(HiOutlineArrowCircleRight)`
  color: #fff;
  position: absolute;
  left: 33%;
  width: 24px;
  height: 24px;
  margin-left: -24px;
  margin-top: -12px;
  top: 80%;
  
   @media (min-width: ${BREAKPOINTS['md']}px) {
    top: 65%;
  }
`;