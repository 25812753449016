import React, {useEffect} from 'react'
import Header from './header/Header'
import History from './history/History'
import Team from './team/Team'
import Layout from "../Layout";
import styled from "styled-components";
import Contact from "../contact";
import {StaticImage} from "gatsby-plugin-image";
import {useAnimate3d} from "../../hooks/useAnimate3d";
import AltTeam from "./AltTeam";
import Production from "../aerial/Production";
import Inspections from "../aerial/Inspections";
import FirstResponders from "../aerial/FirstResponders";
import RealEstate from "../aerial/RealEstate";
import HorizontalScroll, {ScrollPadding} from "../HorizontalScroll";
import GrowthIsStrength from "./GrowthIsStrength";
import ClientComments from "./ClientComments";
import OneTeamOneVision from "./OneTeamOneVision";
import PassionInEverything from "./PassionInEverything";

const Index = () => {
  useAnimate3d();

  const underlay = () => {
    return (
      <>
        {/*<Background className="animate-in-3d-transform">*/}
        {/*  <StaticImage*/}
        {/*    src="../../../images/roninReview_hero@2x.jpg"*/}
        {/*    alt="Unified end to end solutions."*/}
        {/*    loading="eager"*/}
        {/*  />*/}
        {/*</Background>*/}
        <Foreground />
      </>
    );
  }

  return (
    <Layout underlay={underlay()} metatags={{
      title: "JLTech > Join our Family! Find out about us!",
      desc: `Behind the scenes, what really makes JLTech different is our people. We aren't just colleagues and 
        co-workers, we are like family. We all share a passion for creativity and making cool things. 
        A constant craving for pushing our own limits drives us forward to pursue new techniques and
        create strong partnerships with our clients that last.`,
      keywords: `app developers near me, website developers coeur d'alene,
        drupal developers coeur d'alene, react developers coeur d'alene, it services coeur d'alene, app developers coeur d'alene,
        website developers spokane, website designers spokane, website designers coeur d'alene, website developers near me, website designers near me,
        app developers spokane, drone services near me, drone services spokane, react developers spokane, drupal developers spokane, jltech, cda`
    }} snap>
      <Header id="header" />
      <HorizontalScroll id="about-us-scroller">
        <GrowthIsStrength id="growth-is-strength" />
        <ClientComments id="client-comments" />
        <OneTeamOneVision id="one-team-one-vision" />
        <PassionInEverything id="passion-in-everything" />
      </HorizontalScroll>
      <History id="our-history" />
      <Team id="team" />
      <Chat />
    </Layout>
  )
}
export default Index;

const Chat = styled(Contact)`
  height: auto;
  padding: 100px 0;
`;


const Background = styled.div`
  position: fixed;
  height: 120vh;
  width: 120vw;
 
  top: -10vh;
  left: -10vw;
  right: -10vw;
  bottom: -10vh;
  
  .gatsby-image-wrapper {
    width: 120vw;
    height: 120vh;
    max-height: 120vh;
    max-width: 120vw;
  }
`;

const Foreground = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent linear-gradient(180deg, rgba(26, 18, 88, .9) 0%, rgba(26, 18, 88, .9) 100%) 0% 0% no-repeat padding-box;
`;

const PadEnd = styled.div`
  width: 40vw;
  height: 100%;
`;