import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import {CONTAINER, H3, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";
import quotations from "../../assets/icons/quotations.svg";

const ClientComments = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content gutter={{xs: 8, sm: 32, md: 32, lg: 32}}>
          <Column sm={24} md={20} lg={8} xl={8}>
            <TextOverlay>
              <QuotationMark className="animate-in-slide-down animate-out-slide-up" />
              <Title className="animate-in-slide-right animate-out-slide-left">Jade Adair</Title>
              <Paragraph className="animate-in-slide-left animate-out-slide-right">
                ...It feels great to be in business with someone so creative...
              </Paragraph>
            </TextOverlay>
          </Column>
        </Content>
      </Container>
      <Line />
    </Wrapper>
  );
};
export default forwardRef(ClientComments);

const Wrapper = styled.section`
  background: transparent;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  position: relative;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 75vw;
    min-width: 75%;
  }
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const QuotationMark = styled.div`
  position: absolute;
  background-image: url("${quotations}");
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 100px;
  height: 80px;
  right: 0;
  top: 35%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 200px;
    height: 160px;
  }
`;

const Content = styled(Row)`
  width: 100%;
  height: 100%;
  justify-content: center;
`;

const Column = styled(Col)`
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
`;

const TextOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.h2`
  ${H3}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  margin-bottom: 20px;
  z-index: 1;
  margin-top: 10%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-left: -35%;
    max-width: 50%;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  font-family: var(--unnamed-font-family-brandon-grotesque);
  text-align: right;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20%;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    max-width: 60%;
    margin-right: -30%;
    margin-bottom: 50px;
  }
`;

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: #FF3D00;
  position: absolute;
  display: block;
  left: 0;
  top: 80%;
  
   @media (min-width: ${BREAKPOINTS['md']}px) {
    top: 65%;
  }
`;