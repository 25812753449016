import { useStaticQuery, graphql } from "gatsby"

export const useTeam = () => {
  const { team } = useStaticQuery(
    graphql`
      query {
        team: allProfileTeam {
          nodes {
            entity_id: drupal_internal__profile_id
            field_name
            field_position
            field_phone_number
            field_twitter {
              uri
            }
            field_linkedin {
              uri
            }
            field_facebook {
              uri
            }
            field_biography {
              value
            }
            
            relationships {
              field_avatar {
                field_media_image {
                  title
                  alt
                }
                relationships {
                  field_media_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          layout: CONSTRAINED
                          transformOptions: {fit: COVER, cropFocus: ATTENTION}
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return team.nodes && team.nodes.length ? team.nodes : [];
}