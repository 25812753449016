import React, {forwardRef} from "react";
import {HiOutlineArrowCircleDown} from "@react-icons/all-files/hi/HiOutlineArrowCircleDown";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, H3, LEADTEXT} from "../../../styles/Global";
import {BREAKPOINTS} from "../../../constants";
import {StaticImage} from "gatsby-plugin-image";
import ReadMore from "../../ReadMore";

const Header = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content>
          <Title>Hello, We're JLTech</Title>
          <Paragraph>
            {/*Serving businesses in the Northwest*/}
          </Paragraph>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(Header);

const Wrapper = styled(Section)`
  overflow: hidden;
`;

const Container = styled.div`
  ${CONTAINER}
  position: relative;
  height: 100%;
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    position: absolute;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
  }
`;

const Title = styled.h1`
  ${H3}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  opacity: 1;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-bottom: 50px;
  }
`;