import React, {forwardRef} from "react";
import {Row, Col} from "antd";
import styled from "styled-components";
import Section from "../section";
import {CONTAINER, H3, LEADTEXT} from "../../styles/Global";
import {BREAKPOINTS} from "../../constants";

const Team = (props, ref) => {
  return (
    <Wrapper {...props} ref={ref}>
      <Container>
        <Content gutter={{xs: 8, sm: 32, md: 32, lg: 32}}>
          <Col sm={24} md={12} lg={8} xl={8}>
            <Content>
              <Box className="animate-in-3d-transform-reverse animate-in-slide-down animate-out-slide-up" />
              <TextOverlay>
                <Paragraph className="animate-in-slide-left animate-out-slide-right">
                  Our imperfection is what makes us strong. We know we aren't perfect, and that fuels the passion to grow stronger with each day.
                </Paragraph>
                <Title className="animate-in-slide-right animate-out-slide-left">Growth Is Strength</Title>
              </TextOverlay>
            </Content>
          </Col>
        </Content>
      </Container>
    </Wrapper>
  );
};
export default forwardRef(Team);

const Wrapper = styled.section`
  background: transparent;
  width: 75vw;
  min-width: 75%;
  height: 100vh;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Box = styled.div`
  width: 100%;
  height: 40vh;
  background: transparent linear-gradient(180deg, rgba(26, 18, 88, .5) 0%, rgba(65, 45, 219, .9) 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  z-index: 0;
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40vh;
`;

const TextOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  ${H3}
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-right: -10%;
  max-width: 33%;
  z-index: 1
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    margin-bottom: 50px;
  }
`;

const Paragraph = styled.p`
  ${LEADTEXT}
  text-align: left;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 20px;
  max-width: 33%;
  margin-left: -10%;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    margin-bottom: 50px;
  }
`;