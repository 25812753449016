import React from 'react'
import Index from '../components/about'

 const aboutUs = () => {
    return (
        <>
           <Index /> 
        </>
    )
}
export default aboutUs;