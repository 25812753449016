import { useStaticQuery, graphql } from "gatsby"

export const useFullHistory = () => {
  const { history } = useStaticQuery(
    graphql`
      query {
        history: allNodeHistory {
          nodes {
            title
            drupal_internal__nid
            field_date
            body {
              value
            }
            relationships {
              field_profile {
                field_name
                field_position
                relationships {
                  field_avatar {
                    relationships {
                      field_media_image {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return history.nodes && history.nodes.length ? history.nodes : [];
}