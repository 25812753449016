import React, { forwardRef, useRef, useState, useEffect } from "react";
import {HiOutlineArrowCircleUp} from "@react-icons/all-files/hi/HiOutlineArrowCircleUp";
import {HiOutlineArrowCircleDown} from "@react-icons/all-files/hi/HiOutlineArrowCircleDown";
import {HiOutlineArrowCircleRight} from "@react-icons/all-files/hi/HiOutlineArrowCircleRight";
import {GoPrimitiveDot} from "@react-icons/all-files/go/GoPrimitiveDot";
import {Avatar} from "antd";
import styled from "styled-components";
import Section from "../../section";
import {CONTAINER, D1, LEADTEXT, PARAGRAPH} from "../../../styles/Global";
import {BREAKPOINTS} from "../../../constants";
import {useFullHistory} from "../../../hooks/useFullHistory";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import {getSrc} from "gatsby-plugin-image"
import {processImage} from "../../../helpers";

const History = (props, ref) => {
  const history = useFullHistory();

  const containerRef = useRef(null);
  const timelineRef = useRef(null);

  const [state, setState] = useState({
    scroller: null,
    itemHeight: 0,
    isPrevHidden: true,
    isNextHidden: false
  });

  const next = () => {
    state.scroller.scrollBy({left: 0, top: state.itemHeight * 3, behavior: 'smooth'});
    setState({...state, isNextHidden: true, isPrevHidden: false});
  }

  const prev = () => {
    state.scroller.scrollBy({left: 0, top: -state.itemHeight * 3, behavior: 'smooth'});
    setState({...state, isNextHidden: false, isPrevHidden: true});
  }

  useEffect(() => {
    const scroller = timelineRef.current;
    const itemHeight = timelineRef.current.firstElementChild?.clientHeight;
    setState({...state, scroller, itemHeight});

    return () => {}
  },[history]); // eslint-disable-line

  return (
    <Wrapper id="history" {...props} ref={ref}>
      <Container>
        <Title>Our history.</Title>
        <Content ref={containerRef}>
          <Timeline ref={timelineRef}>
            <TimelineBar />
            {history.map((item, index) => {
              const history_body_elements = new ReactHtmlParser(item.body.value);

              const profile = item.relationships.field_profile;
              const avatar = processImage(profile, 'field_avatar');
              const avatarSrc = getSrc(avatar.gatsbyImageData);

              return (
                <TimelineItem key={index}>
                  <TimelineContent>
                    <TimelineLabel>
                      <TimelineDot>
                        <GoPrimitiveDot />
                      </TimelineDot>
                      <TimelineDate>
                        {moment(item.field_date).format("ll")}
                      </TimelineDate>
                      <TimelineTitle>
                        {item.title}
                      </TimelineTitle>
                    </TimelineLabel>
                    <TimelineBody>
                      {history_body_elements}
                    </TimelineBody>
                  </TimelineContent>
                  {profile ? (
                    <Profile>
                      <div>
                        <AvatarIcon />
                        <Avatar size={64} src={avatarSrc} />
                      </div>
                      <div>
                        <ProfileTitle>{profile.field_name}</ProfileTitle>
                        <ProfileSubTitle>{profile.field_position}</ProfileSubTitle>
                      </div>
                    </Profile>
                  ) : null}
                </TimelineItem>
              );
            })}
          </Timeline>
          <PrevButton onClick={() => prev()} hide={state.isPrevHidden}>
            <HiOutlineArrowCircleUp />
          </PrevButton>
          <NextButton onClick={() => next()} hide={state.isNextHidden}>
            <HiOutlineArrowCircleDown />
          </NextButton>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default forwardRef(History);

const Wrapper = styled(Section)`
  background: rgba(241, 240, 245, 1);
  overflow: hidden;
`;

const Container = styled.div`
  ${CONTAINER}
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  padding: 30px 0;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding-top: 100px;
  }
`;

const Content = styled.div`
  position: relative;
`;

const Timeline = styled.div`
  padding: 0 30px;
  height: 60vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  
  /* Hide scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: 60vh;
    width: 75%;
  }
  
  @media (min-width: ${BREAKPOINTS['lg']}px) {
    width: 50%;
  }
`;

const TimelineBar = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 30px;
  border-right: 1px dashed #1a125862;
  
`;

const TimelineItem = styled.div`
  scroll-snap-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`;

const TimelineContent = styled.div`

`;

const TimelineLabel = styled.div`
  position: relative;
  display: flex;
  padding-left: 40px;
  width: 100%;
  margin-bottom: 10px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding-left: 40px;
    margin-bottom: 30px;
  }
`;

const TimelineDot = styled.div`
  color: #ff3d00;
  font-size: 20px;
  width: 40px;
  margin-left: -50px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 40px;
    margin-left: -50px;
  }
`;

const TimelineDate = styled.div`
  ${PARAGRAPH}
  color: rgba(26, 18, 88, .20);
  display: flex;
  padding-right: 20px;
`;

const TimelineTitle = styled.div`
  ${PARAGRAPH}
  color: rgba(255, 61, 0, 1);
  display: flex;
`;

const TimelineBody = styled.div`
  padding-left: 30px;
  
  p {
    ${LEADTEXT}
    text-align: left;
    letter-spacing: 0px;
    color: rgba(26, 18, 88, 1);
    opacity: 1;
    margin-bottom: 20px;
    
    @media (min-width: ${BREAKPOINTS['sm']}px) {
      margin-bottom: 50px;
    }
  }
`;

const Title = styled.h2`
  ${D1}
  font-family: var(--unnamed-font-family-brandon-grotesque);
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .1);
  text-transform: uppercase;
  padding-left: 20px;
  margin-bottom: 40px;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    margin-bottom: 50px;
  }
`;

const PrevButton = styled.button`
  display: ${props => props.hide ? 'none' : 'block'};
  background: transparent;
  border: 0;
  padding: 10px;
  font-size: 24px;
  position: absolute;
  top: -50px;
  left: 8px;
  z-index: 100;
  cursor: pointer;
  height: 50px;
`;

const NextButton = styled(PrevButton)`
  top: auto;
  left: 8px;
  bottom: -30px;
`;

const Profile = styled.div`
  padding-left: 30px;
  display: flex;
  position: relative;
`;

const AvatarIcon = styled(HiOutlineArrowCircleRight)`
  font-size: 32px;
  line-height: 32px;
  position: absolute;
  z-index: 1;
  color: rgba(255,61,0,1);
  transform: translateY(50%);
  left: 10px;
  top: 0px;
`;

const ProfileTitle = styled.div`
  text-align: left;
  font: normal normal bold 20px/24px var(--unnamed-font-family-proxima-nova);
  letter-spacing: 0px;
  color: #1a1258;
  opacity: 1;
  margin-bottom: 0px;
  padding-top: 10px;
  padding-left: 10px;
`;

const ProfileSubTitle = styled.div`
  text-align: left;
  font: normal normal normal 18px/34px var(--unnamed-font-family-brandon-grotesque);
  letter-spacing: 0px;
  color: #ff3d00;
  opacity: 1;
  padding-left: 10px;
  margin: 0px;
`;
