import React, {forwardRef, Fragment, useState} from "react";
import styled from "styled-components";
import {CONTAINER, D1, H3, LEADTEXT, Modal, PARAGRAPH} from "../../../styles/Global";
import {useCardCarousel} from "../../../hooks/useCardCarousel";
import {useTeam} from "../../../hooks/useTeam";
import ReactHtmlParser from "react-html-parser";
import {processImage} from "../../../helpers";
import {GatsbyImage} from "gatsby-plugin-image";
import {AiOutlinePlusCircle} from "@react-icons/all-files/ai/AiOutlinePlusCircle";
import {IoIosCloseCircleOutline} from "@react-icons/all-files/io/IoIosCloseCircleOutline";
import {Col, Row} from "antd";
import {AiFillFacebook} from "@react-icons/all-files/ai/AiFillFacebook";
import {AiFillTwitterSquare} from "@react-icons/all-files/ai/AiFillTwitterSquare";
import {AiFillInstagram} from "@react-icons/all-files/ai/AiFillInstagram";
import {AiFillLinkedin} from "@react-icons/all-files/ai/AiFillLinkedin";
import {BREAKPOINTS} from "../../../constants";
import {Link} from "gatsby";

const Team = (props, ref) => {
  const team = useTeam();

  const {containerRef, carouselRef, cardsRef} = useCardCarousel();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (member) => {
    setIsModalVisible(member);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Wrapper {...props} ref={ref}>
      <Title>Our Team.</Title>
      <Carousel ref={containerRef}>
        <Cards ref={carouselRef}>
          <CardList ref={cardsRef}>
            {team.map((member, index) => {
              const biography_elements = member.field_biography ? new ReactHtmlParser(member.field_biography.value) : null;
              const avatar = processImage(member, 'field_avatar');

              const ModalTitle = () => {
                return (
                  <TeamMember>
                    <Name>{member.field_name}</Name>
                    <Position>{member.field_position}</Position>
                  </TeamMember>
                )
              }

              return (
                <Fragment key={index}>
                  <CardItem>
                    <Card>
                      <CardContent>
                        <CardImage>
                          {avatar.gatsbyImageData ? (
                            <GatsbyImage
                              image={avatar.gatsbyImageData}
                              alt={avatar.alt}
                              title={avatar.title}
                              loading="eager"
                            />
                          ) : null}
                        </CardImage>
                        <CardBody>
                          <TeamMember>
                            <Name>{member.field_name}</Name>
                            <Position>{member.field_position}</Position>
                          </TeamMember>
                          <InfoIcon onClick={() => showModal(member.entity_id)}>
                            <AiOutlinePlusCircle />
                          </InfoIcon>
                        </CardBody>
                      </CardContent>
                    </Card>
                    <Modal title={<ModalTitle />} visible={isModalVisible === member.entity_id} onCancel={handleCancel} closeIcon={<IoIosCloseCircleOutline />} footer={null} width="auto">
                      <Row>
                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                          <ModalImage>
                            <CardImage>
                              {avatar.gatsbyImageData ? (
                                <GatsbyImage
                                  image={avatar.gatsbyImageData}
                                  alt={avatar.alt}
                                  title={avatar.title}
                                  loading="eager"
                                />
                              ) : null}
                            </CardImage>
                          </ModalImage>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                          <ModalBody>
                            <ModalBiography>
                              {biography_elements}
                            </ModalBiography>
                            <ModalSocial>
                              {member.field_facebook ? (
                                <SocialButton
                                  to={member.field_facebook.uri}
                                  target="_blank"
                                ><AiFillFacebook /></SocialButton>
                              ) : null}
                              {member.field_twitter ? (
                                <SocialButton
                                  to={member.field_twitter.uri}
                                  target="_blank"
                                ><AiFillTwitterSquare /></SocialButton>
                              ) : null}
                              {member.field_instagram ? (
                                <SocialButton
                                  to={member.field_instagram.uri}
                                  target="_blank"
                                ><AiFillInstagram /></SocialButton>
                              ) : null}
                              {member.field_linkedin ? (
                                <SocialButton
                                  to={member.field_linkedin.uri}
                                  target="_blank"
                                ><AiFillLinkedin /></SocialButton>
                              ) : null}

                            </ModalSocial>
                          </ModalBody>
                        </Col>
                      </Row>
                    </Modal>
                  </CardItem>
                </Fragment>
              );
            })}
          </CardList>
        </Cards>
      </Carousel>
    </Wrapper>
  );
};
export default forwardRef(Team);

const Wrapper = styled.section`
  background: transparent;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

const Title = styled.h2`
  ${D1}
  font-family: var(--unnamed-font-family-brandon-grotesque);
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: rgba(26, 18, 88, .1);
  text-transform: uppercase;
  padding-left: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  position: absolute;
  
  @media (min-width: ${BREAKPOINTS['sm']}px) {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const Carousel = styled.div`
  ${CONTAINER}
  padding: 0;
  height: 100vh;
  display: flex;
  overflow: scroll;
  
  /* Hide scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    padding: 0 40px;
  }
`;


const Cards = styled.div`
  width: 100%;
  height: 300vh;
  overflow: hidden;
`;

const CardList = styled.ul`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 24rem;
  height: 40rem;
  top: 20%;
  left: 50%;
  margin: 0 2rem;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 28rem;
    height: 40rem;
  }
`;

const CardItem = styled.li`
  padding: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 20rem;
  height: 36rem;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0px 0px 100px 0px rgba(255, 255, 255, .2);
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    width: 24rem;
    height: 36rem;
  }
`;

const Card = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
  }
`;
const CardContent = styled.div``;
const CardImage = styled.div`
  margin-right: 0;
  position: relative;
  overflow: hidden;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    height: auto;
  }
`;
const CardBody = styled.div`
  line-height: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #fff;
`;

const TeamMember = styled.div`
  margin-top: 10px;
`;

const Name = styled.h2`
  text-align: left;
  position: relative;
  line-height: 110%;
  font-size: 18px;
  font-family: var(--unnamed-font-family-proxima-nova);
  color: rgba(26, 18, 88, 1);
  font-weight: bold;
`;

const Position = styled.h3`
  text-align: left;
  position: relative;
  line-height: 110%;
  font-size: 18px;
  font-family: var(--unnamed-font-family-proxima-nova);
  color: rgba(255, 61, 0, 1);
`;

const InfoIcon = styled.button`
  border: 0;
  background: transparent;
  font-size: 30px;
  color: rgba(255, 61, 0, 1);
`;

const ModalImage = styled.div`
  margin-right: 0;
  height: 160px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    margin-right: 50px;
    margin-bottom: 0;
    height: 100%;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  position: relative;
`;

const ModalBiography = styled.div`
  p {
    ${LEADTEXT}
    color: rgba(26, 18, 88, 1);
  }
  strong {
    color: rgba(255, 61, 0, 1);
  }
`;

const ModalSocial = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
`;

const SocialButton = styled(Link)`
  font-size: 40px;
  line-height: 100%;
  color: rgba(26, 18, 88, 1);
  
  @media (min-width: ${BREAKPOINTS['md']}px) {
    font-size: 60px;
  }
`