import { useEffect } from "react";
import { gsap, ScrollToPlugin } from "gsap/all";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export const useAnimate3d = () => {
  let mouse = { x: 0, y: 0 };
  let cx = typeof window !== 'undefined' && window.innerWidth / 2;
  let cy = typeof window !== 'undefined' && window.innerHeight / 2;

  const mouseMoved = (event) => {
    mouse.x = event.layerX;
    mouse.y = event.layerY;

    let dx = mouse.x - cx;
    let dy = mouse.y - cy;

    let tiltx = (dy / cy);
    let tilty = - (dx / cx);
    let radius = Math.sqrt(Math.pow(tiltx,2) + Math.pow(tilty,2));

    let degree = (radius * 20);

    if(document.querySelector(".animate-in-3d-transform")) {
      gsap.to(".animate-in-3d-transform", {
        transform: 'rotate3d(' + tiltx + ', ' + tilty + ', 0, ' + degree + 'deg)',
        ease: "ease-out"
      });
    }
    if(document.querySelector(".animate-in-3d-transform-reverse")) {
      gsap.to(".animate-in-3d-transform-reverse", {
        transform: `rotate3d(${tilty}, ${tiltx}, 0, ${-1 * degree}deg)`,
        ease: "ease-out"
      });
    }

  }

  useEffect(() => {
    window.addEventListener('mousemove', mouseMoved);

    return () => {
      window.removeEventListener('mousemove', mouseMoved);
    };
  }, []);

  return {};
}